<template>
  <base-section
    id="download-install"
    class="grey lighten-4"
  >
    <v-container class="fill-height px-4 py-12">
      <v-row justify="center">
        <v-col
          cols="12"
          md="8"
        >
          <v-responsive
            class="d-flex align-center"
          >
            <!--<base-info-card-->
              <!--v-bind="downloads[0]"-->
            <!--/>-->

            <base-subheading title="OlapdDB 1.0" />

            <base-body>
              OlapDB's deployment requires the use of HBase service. Before deploying OlapDB's components, you need have HBase first.
            </base-body>

            <base-subheading title="1. Download OlapDB HBase coprocessor" />

            <base-body>
              OlapDB HBase coprocessor is a plug-in that runs on the HBase server side and can intercept HBase's read and write requests and perform custom processing. OlapDB uses a dedicated HBase coprocessor to optimize the read/write and delete operations of aggregated data, thus improving the efficiency of data storage and processing.
              <br>
              <br>
              Download Hbase Cocprcessor:  <a href="https://www.olapdb.com/downloads/olapdb/v1.0/olap-observer-1.0.jar"> olap-observer-1.0.jar</a>
              <br>
              <base-code>
                <pre>{{code1}}</pre>
              </base-code>
              <br>
              Before installing and deploying other services, you need to copy the coprocessor's jar file olap-observer-1.0.jar file to a location that the HBase cluster can access.
            </base-body>

            <base-subheading title="2. Start OlapDB via Docker image" />

            <base-body>
              The Docker image provided by OlapDB integrates management service, build service, query service and web management console. You can start these services by starting the image.
              <br>
              <br>
              <base-code>
                <pre>{{code2}}</pre>
              </base-code>

              <br>
              The image startup parameters are 8080 port for web management service, 10084 for Rest fact data submission port, 10086 for management service port, 10088 for query service query port. --add-host zkServer:xxx.xxx.xxx.xxx adds the IP mapping of HBase cluster's ZooKeeper service to the host record of the image, -e ZK_QUORUM=monitor specifies the HBase cluster to be used by the service by specifying the ZooKeeper address of HBase.
              <br>
              <br>
              You can now open the cluster web management console via http://image host machine IP:8080.
              <br>
              <br>

              <base-code>
                <pre>{{code3}}</pre>
              </base-code>
              <br>
            </base-body>

            <base-subheading title="3. Download and start OlapDB service seperately" />

            <base-body>
              <br>
              <h2>Management service</h2>
              <br>

              The management service is responsible for scheduling and monitoring cube building and query tasks, and provides data warehouse management and monitoring functions through web management console.
              <br>
              Download master service:  <a href="https://www.olapdb.com/downloads/olapdb/v1.0/olap-master-1.0.jar"> olap-master-1.0.jar</a>
              <br>
              <br>
              Management service startup command:
              <br>
              <br>
              <base-code>
                <pre>{{code4}}</pre>
              </base-code>
              <br>
              <br>
              <h2>Build service</h2>
              <br>

              The build service is responsible for scanning the fact table, discovering the fact data increment specified in the model, and building the incremental data segment of the cube. The build service also provides a Rest interface for users to directly submit data to OlapDB.
              <br>
              <br>
              Download builder service:  <a href="https://www.olapdb.com/downloads/olapdb/v1.0/olap-builder-1.0.jar"> olap-builder-1.0.jar</a>
              <br>
              <br>
              Startup command:
              <br>

              <base-code>
                <pre>{{code5}}</pre>
              </base-code>
              <br>
              <br>
              <h2>Query service</h2>
              <br>

              The query service is responsible for providing data query service. If the query task load is heavy, multiple nodes can be deployed. Startup command:
              <br>
              <br>
              Download query service:  <a href="https://www.olapdb.com/downloads/olapdb/v1.0/olap-query-1.0.jar"> olap-query-1.0.jar</a>
              <br>
              <br>
              Startup command:
              <br>
              <br>
              <base-code>
                <pre>{{code6}}</pre>
              </base-code>
              <br>
              <h2>Web management console</h2>
              <br>

              Web management console is a web application that provides a friendly user interface for users to manage and monitor OlapDB data warehouse.<b><bold>Web management console needs to be deployed on the same server as management service</bold></b>.

              <br>
              <br>
              Download web management console:  <a href="https://www.olapdb.com/downloads/olapdb/v1.0/olap-manager-web.html.tar.gz"> olap-manager-web.html.tar.gz</a>
              <br>
              <br>
              Install instructions under CentOS 7:
              <br>
              <br>
              Install nginx if not exist:
              <br>
              <br>

              <base-code>
                <pre>yum install nginx</pre>
              </base-code>
              <br>
              Download OlapDB Web management console and install:
              <br>
              <br>
              <base-code>
                <pre>{{code7}}</pre>
              </base-code>
              <br>
              Open the browser and visit http://localhost:80, you can see the web management console interface.
            </base-body>
          </v-responsive>
        </v-col>

      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionDownloadInstall',
    data: () => ({
      downloads: [
        {
          title: 'startReason1Title',
          subtitle: 'Step 1',
          html: 'startReason1Content',
          callout: '01',
        },
      ],
      code1: 'wget https://www.olapdb.com/downloads/olapdb/v1.0/olap-observer-1.0.jar',
      code2: '//Pull image\n' +
        'docker pull nvwaonline/olapdb:latest\n' +
        '//Start image\n' +
        'docker run --name olapdb -d -p 8080:80 -p 10084:10084 -p 10086:10086 -p 10088:10088 --add-host zkServer:xxx.xxx.xxx.xxx -e ZK_QUORUM=zkServer nvwaonline/olapdb',
      code3: 'http://image host machine IP:8080',
      code4: 'wget https://www.olapdb.com/downloads/olapdb/v1.0/olap-master-1.0.jar\n' +
        'nohup java -jar olap-master-1.0.jar --hbase.zkQuorum=quorumServer &',
      code5: 'wget https://www.olapdb.com/downloads/olapdb/v1.0/olap-builder-1.0.jar\n' +
        'nohup java -jar olap-builder-1.0.jar --hbase.zkQuorum=quorumServer &',
      code6: 'wget https://www.olapdb.com/downloads/olapdb/v1.0/olap-query-1.0.jar\n' +
        'nohup java -jar olap-query-1.0.jar --hbase.zkQuorum=quorumServer &',
      code7: 'wget https://www.olapdb.com/downloads/olapdb/v1.0/olap-manager-web.html.tar.gz\n' +
        'tar -zxvf olap-manager-web.html.tar.gz\n' +
        'mv olap-manager-web /usr/share/nginx/html\n' +
        'systemctl start nginx',
    }),
  }

</script>
